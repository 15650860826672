import { FC } from "react";
import styled from '@emotion/styled';
import { CSSObject } from '@emotion/react';

type Props = {
  size?: 'S' | 'M' | 'L';
  theme?: 'LIGHT' | 'DARK';
  color?: string;
  localStyles?: CSSObject;
}

const El = styled.svg(({localStyles}: Props) => ({
  ...(localStyles)
}));

export const External: FC<Props> = ({ color = 'white', theme = 'LIGHT', size = "S", localStyles }) => {
  return (
    <>
      {size === "S" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          fill="none"
          viewBox="0 0 17 17"
          >
          {theme === 'LIGHT' && <path fill="var(--light-1000)" d="M3.69618 16C1.93849 16 1 15.0774 1 13.3356V3.67232C1 1.93054 1.93849 1 3.69618 1H13.3038C15.0695 1 16 1.93054 16 3.67232V13.3356C16 15.0774 15.0695 16 13.3038 16H3.69618ZM6.66278 12.5801C6.92524 12.8346 7.45811 12.8187 7.73648 12.5562L11.0689 9.43054C11.6018 8.93743 11.6018 8.07052 11.0689 7.57741L7.73648 4.45175C7.43425 4.16543 6.96501 4.15748 6.67869 4.42789C6.36851 4.72216 6.36055 5.22322 6.66278 5.50954L9.86002 8.5L6.66278 11.4984C6.36851 11.7847 6.3526 12.2619 6.66278 12.5801Z" />}
          {theme === 'DARK' && <path fill="var(--dark-1000)" d="M3.69618 16C1.93849 16 1 15.0774 1 13.3356V3.67232C1 1.93054 1.93849 1 3.69618 1H13.3038C15.0695 1 16 1.93054 16 3.67232V13.3356C16 15.0774 15.0695 16 13.3038 16H3.69618ZM6.66278 12.5801C6.92524 12.8346 7.45811 12.8187 7.73648 12.5562L11.0689 9.43054C11.6018 8.93743 11.6018 8.07052 11.0689 7.57741L7.73648 4.45175C7.43425 4.16543 6.96501 4.15748 6.67869 4.42789C6.36851 4.72216 6.36055 5.22322 6.66278 5.50954L9.86002 8.5L6.66278 11.4984C6.36851 11.7847 6.3526 12.2619 6.66278 12.5801Z" />}
        </El>
      }
      {size === "M" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="none"
          viewBox="0 0 19 19"
        >
        {theme === 'LIGHT' && <path fill="var(--light-1000)" d="M4.37593 17.5C2.50106 17.5 1.5 16.5159 1.5 14.658V4.35048C1.5 2.49258 2.50106 1.5 4.37593 1.5H14.6241C16.5074 1.5 17.5 2.49258 17.5 4.35048V14.658C17.5 16.5159 16.5074 17.5 14.6241 17.5H4.37593ZM7.5403 13.8521C7.82025 14.1235 8.38865 14.1066 8.68558 13.8266L12.2402 10.4926C12.8086 9.9666 12.8086 9.04189 12.2402 8.51591L8.68558 5.18187C8.3632 4.87646 7.86267 4.86797 7.55726 5.15642C7.22641 5.47031 7.21792 6.00477 7.5403 6.31018L10.9507 9.5L7.5403 12.6983C7.22641 13.0037 7.20944 13.5127 7.5403 13.8521Z" />}
        {theme === 'DARK' && <path fill="var(--dark-1000)" d="M4.37593 17.5C2.50106 17.5 1.5 16.5159 1.5 14.658V4.35048C1.5 2.49258 2.50106 1.5 4.37593 1.5H14.6241C16.5074 1.5 17.5 2.49258 17.5 4.35048V14.658C17.5 16.5159 16.5074 17.5 14.6241 17.5H4.37593ZM7.5403 13.8521C7.82025 14.1235 8.38865 14.1066 8.68558 13.8266L12.2402 10.4926C12.8086 9.9666 12.8086 9.04189 12.2402 8.51591L8.68558 5.18187C8.3632 4.87646 7.86267 4.86797 7.55726 5.15642C7.22641 5.47031 7.21792 6.00477 7.5403 6.31018L10.9507 9.5L7.5403 12.6983C7.22641 13.0037 7.20944 13.5127 7.5403 13.8521Z" />}
      </El>
      }
      {size === "L" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="none"
          viewBox="0 0 25 25"
        >
          {theme === 'LIGHT' && <path fill="var(--light-1000)" d="M5.4544 23.5C2.87646 23.5 1.5 22.1469 1.5 19.5923V5.41941C1.5 2.86479 2.87646 1.5 5.4544 1.5H19.5456C22.1352 1.5 23.5 2.86479 23.5 5.41941V19.5923C23.5 22.1469 22.1352 23.5 19.5456 23.5H5.4544ZM9.80541 18.4841C10.1904 18.8574 10.9719 18.834 11.3802 18.4491L16.2678 13.8648C17.0493 13.1416 17.0493 11.8701 16.2678 11.1469L11.3802 6.56257C10.9369 6.14263 10.2487 6.13097 9.82874 6.52757C9.37381 6.95917 9.36214 7.69406 9.80541 8.114L14.4947 12.5L9.80541 16.8977C9.37381 17.3176 9.35048 18.0175 9.80541 18.4841Z" />}
          {theme === 'DARK' && <path fill="var(--dark-1000)" d="M5.4544 23.5C2.87646 23.5 1.5 22.1469 1.5 19.5923V5.41941C1.5 2.86479 2.87646 1.5 5.4544 1.5H19.5456C22.1352 1.5 23.5 2.86479 23.5 5.41941V19.5923C23.5 22.1469 22.1352 23.5 19.5456 23.5H5.4544ZM9.80541 18.4841C10.1904 18.8574 10.9719 18.834 11.3802 18.4491L16.2678 13.8648C17.0493 13.1416 17.0493 11.8701 16.2678 11.1469L11.3802 6.56257C10.9369 6.14263 10.2487 6.13097 9.82874 6.52757C9.37381 6.95917 9.36214 7.69406 9.80541 8.114L14.4947 12.5L9.80541 16.8977C9.37381 17.3176 9.35048 18.0175 9.80541 18.4841Z" />}
      </El>
      }
    </>
  );
}

export default External;

