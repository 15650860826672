import { FC } from "react";
import styled from '@emotion/styled';
import { CSSObject } from '@emotion/react';

type Props = {
  localStyles?: CSSObject;
}

const El = styled.svg(({localStyles }: Props) => ({
  ...(localStyles)
}));

const Logo: FC<Props> = ({ localStyles }) => {
  return (
    <El
      localStyles={localStyles}
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="29"
      fill="none"
      viewBox="0 0 58 29"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.68542 28.2409H0V0.819336H7.96737C8.90301 0.826533 11.0679 1.20367 12.2425 2.65464C13.7108 4.46835 13.7108 6.21728 13.7108 8.13895V11.5289C13.7036 12.3421 13.4733 14.2278 12.6096 15.2642C11.53 16.5597 10.105 17.5961 7.881 17.5961H4.68542V28.2409ZM4.68542 13.1482V5.31042H7.12529C7.44197 5.30322 8.15738 5.41406 8.48557 5.91499C8.89581 6.54115 8.939 6.88662 8.939 7.9878V11.0322C8.91021 11.7376 8.50716 13.1482 7.12529 13.1482H4.68542Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.394 23.9442L17.5326 28.2409H12.8904L19.4543 0.862549H22.7794L29.3865 28.2409H24.7227L23.8561 23.9442H18.394ZM19.2598 19.6258H21.2248H22.9851L21.1168 10.3629L19.2598 19.6258Z" fill="white"/>
      <path d="M34.957 12.2198L32.3876 0.862549H27.5078L32.6898 17.7905V28.1977H37.3321V17.7905L42.3198 0.862549H37.44L34.957 12.2198Z" fill="white"/>
      <path d="M52.5544 9.71515H57.3478V7.62075C57.3478 1.50596 52.9143 0.337124 50.6975 0.517057C49.949 0.488268 48.0331 0.72002 46.3576 1.87734C44.6821 3.03466 44.1336 5.42559 44.0688 6.47639V22.1304C44.0688 23.5987 44.6302 25.4987 46.1201 26.9886C47.6099 28.4784 49.5316 28.5 51.5396 28.5C53.5476 28.5 54.8863 27.4204 56.0523 26.1681C56.9851 25.1662 57.3046 22.5263 57.3478 21.3315V19.3667H52.5544V21.1372C52.6912 22.0297 52.5199 23.8146 50.7407 23.8146C48.9615 23.8146 48.7615 22.0297 48.8838 21.1372V7.62075C48.8262 6.83625 49.1084 5.26725 50.6975 5.26725C52.2867 5.26725 52.5976 6.83625 52.5544 7.62075V9.71515Z" fill="white"/>
  </El>
  );
}

export default Logo;

