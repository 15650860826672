import { FC } from "react";
import styled from '@emotion/styled';
import { CSSObject } from '@emotion/react';

type Props = {
  size?: 'S' | 'M' | 'L';
  theme?: 'LIGHT' | 'DARK';
  color?: string;
  localStyles?: CSSObject;
}

const El = styled.svg(({localStyles}: Props) => ({
  ...(localStyles)
}));

export const Youtube: FC<Props> = ({ color = 'white', theme = 'LIGHT', size = "S", localStyles }) => {
  return (
    <>
      {size === "S" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          fill="none"
          viewBox="0 0 17 17"
      >
        {theme === 'LIGHT' && <path fill="var(--light-1000)" fill-rule="evenodd" clip-rule="evenodd" d="M12.024 2.23462H4.97605C2.48869 2.23462 0.47229 4.25102 0.47229 6.73838V10.2616C0.47229 12.749 2.48869 14.7654 4.97605 14.7654H12.024C14.5113 14.7654 16.5277 12.749 16.5277 10.2616V6.73838C16.5277 4.25102 14.5113 2.23462 12.024 2.23462ZM12.024 4.23462H4.97605C3.59326 4.23462 2.47229 5.35559 2.47229 6.73838V10.2616C2.47229 11.6444 3.59326 12.7654 4.97605 12.7654H12.024C13.4068 12.7654 14.5277 11.6444 14.5277 10.2616V6.73838C14.5277 5.35559 13.4068 4.23462 12.024 4.23462ZM10.2981 8.56829L7.00155 10.1405C6.91372 10.1824 6.81225 10.1184 6.81225 10.0211V6.77831C6.81225 6.67962 6.91638 6.61565 7.00441 6.66027L10.3009 8.33079C10.399 8.38045 10.3973 8.52101 10.2981 8.56829Z"/>}
        {theme === 'DARK' &&  <path fill="var(--dark-1000)" fill-rule="evenodd" clip-rule="evenodd" d="M12.024 2.23462H4.97605C2.48869 2.23462 0.47229 4.25102 0.47229 6.73838V10.2616C0.47229 12.749 2.48869 14.7654 4.97605 14.7654H12.024C14.5113 14.7654 16.5277 12.749 16.5277 10.2616V6.73838C16.5277 4.25102 14.5113 2.23462 12.024 2.23462ZM12.024 4.23462H4.97605C3.59326 4.23462 2.47229 5.35559 2.47229 6.73838V10.2616C2.47229 11.6444 3.59326 12.7654 4.97605 12.7654H12.024C13.4068 12.7654 14.5277 11.6444 14.5277 10.2616V6.73838C14.5277 5.35559 13.4068 4.23462 12.024 4.23462ZM10.2981 8.56829L7.00155 10.1405C6.91372 10.1824 6.81225 10.1184 6.81225 10.0211V6.77831C6.81225 6.67962 6.91638 6.61565 7.00441 6.66027L10.3009 8.33079C10.399 8.38045 10.3973 8.52101 10.2981 8.56829Z"/>}
      </El>
      }
      {size === "M" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          fill="none"
          viewBox="0 0 19 19"
          >
            {theme === 'LIGHT' && <path fill="var(--light-1000)" fill-rule="evenodd" clip-rule="evenodd" d="M13.8527 2.23145H5.14731C2.33477 2.23145 0.0547485 4.51146 0.0547485 7.32401V11.6758C0.0547485 14.4883 2.33477 16.7683 5.14731 16.7683H13.8527C16.6652 16.7683 18.9452 14.4883 18.9452 11.6758V7.32401C18.9452 4.51146 16.6652 2.23145 13.8527 2.23145ZM13.8527 4.23145H5.14731C3.43934 4.23145 2.05475 5.61603 2.05475 7.32401V11.6758C2.05475 13.3837 3.43934 14.7683 5.14731 14.7683H13.8527C15.5607 14.7683 16.9452 13.3837 16.9452 11.6758V7.32401C16.9452 5.61603 15.5607 4.23145 13.8527 4.23145ZM11.7209 9.58408L7.64915 11.5261C7.54065 11.5778 7.41533 11.4987 7.41533 11.3785V7.37315C7.41533 7.25125 7.54395 7.17225 7.65267 7.22735L11.7245 9.29073C11.8455 9.35207 11.8434 9.52568 11.7209 9.58408Z" />}
            {theme === 'DARK' && <path fill="var(--dark-1000)" fill-rule="evenodd" clip-rule="evenodd" d="M13.8527 2.23145H5.14731C2.33477 2.23145 0.0547485 4.51146 0.0547485 7.32401V11.6758C0.0547485 14.4883 2.33477 16.7683 5.14731 16.7683H13.8527C16.6652 16.7683 18.9452 14.4883 18.9452 11.6758V7.32401C18.9452 4.51146 16.6652 2.23145 13.8527 2.23145ZM13.8527 4.23145H5.14731C3.43934 4.23145 2.05475 5.61603 2.05475 7.32401V11.6758C2.05475 13.3837 3.43934 14.7683 5.14731 14.7683H13.8527C15.5607 14.7683 16.9452 13.3837 16.9452 11.6758V7.32401C16.9452 5.61603 15.5607 4.23145 13.8527 4.23145ZM11.7209 9.58408L7.64915 11.5261C7.54065 11.5778 7.41533 11.4987 7.41533 11.3785V7.37315C7.41533 7.25125 7.54395 7.17225 7.65267 7.22735L11.7245 9.29073C11.8455 9.35207 11.8434 9.52568 11.7209 9.58408Z" />}
        </El>
      }
      {size === "L" && 
        <El
          localStyles={localStyles}
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="none"
          viewBox="0 0 25 25"
          >
            {theme === 'LIGHT' && <path fill="var(--light-1000)" fill-rule="evenodd" clip-rule="evenodd" d="M18.3476 3.34204H6.65247C3.23215 3.34204 0.497803 6.13997 0.497803 9.5438V15.4564C0.497803 18.8602 3.23215 21.6581 6.65247 21.6581H18.3476C21.7679 21.6581 24.5022 18.8602 24.5022 15.4564V9.5438C24.5022 6.13997 21.7679 3.34204 18.3476 3.34204ZM18.3476 5.34204H6.65247C4.35791 5.34204 2.4978 7.22323 2.4978 9.5438V15.4564C2.4978 17.7769 4.35791 19.6581 6.65247 19.6581H18.3476C20.6421 19.6581 22.5022 17.7769 22.5022 15.4564V9.5438C22.5022 7.22323 20.6421 5.34204 18.3476 5.34204ZM15.4837 12.6149L10.0135 15.2534C9.86777 15.3237 9.6994 15.2162 9.6994 15.0529V9.61095C9.6994 9.44533 9.87219 9.33798 10.0183 9.41285L15.4884 12.2163C15.6511 12.2996 15.6483 12.5355 15.4837 12.6149Z" />}
            {theme === 'DARK' && <path fill="var(--dark-1000)" fill-rule="evenodd" clip-rule="evenodd" d="M18.3476 3.34204H6.65247C3.23215 3.34204 0.497803 6.13997 0.497803 9.5438V15.4564C0.497803 18.8602 3.23215 21.6581 6.65247 21.6581H18.3476C21.7679 21.6581 24.5022 18.8602 24.5022 15.4564V9.5438C24.5022 6.13997 21.7679 3.34204 18.3476 3.34204ZM18.3476 5.34204H6.65247C4.35791 5.34204 2.4978 7.22323 2.4978 9.5438V15.4564C2.4978 17.7769 4.35791 19.6581 6.65247 19.6581H18.3476C20.6421 19.6581 22.5022 17.7769 22.5022 15.4564V9.5438C22.5022 7.22323 20.6421 5.34204 18.3476 5.34204ZM15.4837 12.6149L10.0135 15.2534C9.86777 15.3237 9.6994 15.2162 9.6994 15.0529V9.61095C9.6994 9.44533 9.87219 9.33798 10.0183 9.41285L15.4884 12.2163C15.6511 12.2996 15.6483 12.5355 15.4837 12.6149Z" />}
        </El>
      }
    </>
  );
}

export default Youtube;




